<template>
  <div>
    <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
      <md-card-header>
        <div class="md-title">Settings</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="changeEvent">
              <md-icon>change_circle</md-icon>
              Change event or date
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="scanSettings">
              <md-icon>qr_code_scanner</md-icon>
              Scan Settings from QR
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="importSettings">
              <md-icon>file_upload</md-icon>
              Import settings from file
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="viewSettings">
              <md-icon>preview</md-icon>
              View settings
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="sendEntryUpdates">
              <md-icon>send</md-icon>
              Send updates
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-primary md-raised" type="button" @click="reloadEntryCache">
              <md-icon>refresh</md-icon>
              Refresh cache
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button class="md-accent md-raised" type="button" @click="resetSettings">
              <md-icon>delete</md-icon>
              Reset settings
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-switch id="audible_warnings" v-model="audibleOn" @change="audibleChanged" class="md-primary">
              Audible scan warnings
            </md-switch>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-switch id="cache_event_entry_data" v-model="cacheEntryData" @change="cacheStatusChanged" class="md-primary">
              Cache entry data for fast scanning
            </md-switch>
          </div>
          <p v-if="message" v-html="message" class="md-alignment-center"></p>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<style scoped>
h1 {
  color: green
}
button {
  width: 100%;
}
.md-switch {
  display: flex;
  margin-left: 8px;
}
.v-distance {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<script>
import SettingStore from "@/SettingStore";
import router from "@/router";
import eventEntry from "@/EventEntryStore";

export default {
  components: {},
  data : function() {
    return {
      message: '',
      audibleOn: false,
      cacheEntryData: false,
      result: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    scanSettings: function() {
      this.$router.push('/settings/server');
    },
    importSettings: function() {
      this.$router.push('/settings/import');
    },
    viewSettings: function() {
      this.$router.push('/settings/view');
    },
    changeEvent: function() {
      this.$router.push('/change-event');
    },
    resetSettings: function() {
      SettingStore.reset();
      this.message = "Settings reset to installation defaults";
    },
    audibleChanged: function() {
      SettingStore.setAudible(this.audibleOn);
      SettingStore.saveConfig();
    },
    cacheStatusChanged: function() {
      SettingStore.setCachingEventEntryData(this.cacheEntryData);
      SettingStore.saveConfig();
    },
    sendEntryUpdates: function() {
      eventEntry.safeSendEntryUpdates();
    },
    reloadEntryCache: function () {
      eventEntry.fetchEntryData(
          SettingStore.getApiUri(),
          SettingStore.getEntityId(),
          SettingStore.getEventId(),
          SettingStore.getEventDate()
      )
    }
  },
  mounted() {
    SettingStore.init();
    this.audibleOn = SettingStore.isAudible();
    this.cacheEntryData = SettingStore.isCachingEventEntryData();
    eventEntry.init();
  }
}
</script>

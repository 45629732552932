<template>
  <div>
    <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
      <md-card-header>
        <div class="md-title">Scan ticket to:</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 v-distance">
            <md-button type="button" @click="entry" class="md-raised md-primary">
              <md-icon>meeting_room</md-icon>
              Enter Event
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button type="button" @click="exit" class="md-raised">
              <md-icon>directions_walk</md-icon>
              Exit from Event
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button type="button" @click="temporary" class="md-raised">
              <md-icon>watch</md-icon>
              Temporary Exit
            </md-button>
          </div>
          <div class="md-layout-item md-size-100 v-distance">
            <md-button type="button" @click="info" class="md-raised">
              <md-icon>fact_check</md-icon>
              Check Ticket Information
            </md-button>
          </div>
        </div>
        <p>Set this once. Your chosen action will apply to all subsequent tickets until you choose to change the action.</p>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "ScanAction",
  props: {
  },
  methods: {
    entry: function() {
      this.$emit('selected', 'ticket_entry_event');
    },
    exit: function() {
      this.$emit('selected','ticket_exit_event');
    },
    temporary: function() {
      this.$emit('selected', 'ticket_temp_exit');
    },
    info: function() {
      this.$emit('selected', 'ticket_info_event');
    }
  }
}
</script>

<style scoped>
  button {
    width: 100%;
  }
  .v-distance {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
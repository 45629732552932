<template>
  <div>
    <md-card class="md-layout-item md-size-100 md-small-size-100">
      <md-card-header>
        <div class="md-title">Change Event and Date</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
        <event-settings :entity_id="entity_id" :event_id="event_id" :event_date="event_date" v-on:save-event="saveEvent"></event-settings>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<style scoped>
h1 {
  color: green
}
</style>

<script>
import SettingStore from "@/SettingStore";
import EventSettings from "@/components/EventSettings";

export default {
  components: {EventSettings},
  data : function() {
    return {
      entity_id: 0,
      event_id: 0,
      event_date: '',
    }
  },
  methods: {
    saveEvent: function(settings) {
      SettingStore.setEventId(settings.selected_event_id);
      SettingStore.setEventName(settings.selected_event_name);
      SettingStore.setEventDate(settings.selected_event_date);
      SettingStore.setEventTimeSlot(settings.selected_time_slot);
      SettingStore.saveConfig();
      setTimeout(this.goToScan, 2000);
    },
    goToScan: function() {
      this.$router.push('/scan')
    }

  },
  mounted() {
    SettingStore.init();
    this.entity_id = SettingStore.getEntityId();
    this.event_id = SettingStore.getEventId();
    this.event_date = SettingStore.getEventDate();
  }
}
</script>

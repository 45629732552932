import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import {
//   MdList,
//   MdContent,
//   MdButton,
//   MdIcon,
//   MdApp,
//   MdToolbar,
//   MdDrawer,
//   MdTable,
//   MdField,
//   MdMenu,
// } from 'vue-material/dist/components'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

// Vue.use(MdList)
// Vue.use(MdButton)
// Vue.use(MdContent)
// Vue.use(MdIcon)
// Vue.use(MdApp)
// Vue.use(MdToolbar)
// Vue.use(MdDrawer)
// Vue.use(MdTable)
// Vue.use(MdField)
// Vue.use(MdMenu)
Vue.use(VueMaterial)

Vue.config.productionTip = false;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker-cache.js')
    .then(() => {
      console.log('Service Worker Registered')
    })
}

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
});

import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Home from '@/components/Home'
import Scan from '@/components/Scan'
import Settings from '@/components/Settings'
import ViewSettings from '@/components/ViewSettings'
import RemoteConfig from "@/components/RemoteConfig";
import ChangeEvent from "@/components/ChangeEvent"
import SetEvent from "@/components/SetEvent";
import ImportConfig from "@/components/ImportConfig";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/scan',
      name: 'Scan',
      component: Scan
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
    {
      path: '/settings/view',
      name: 'View Settings',
      component: ViewSettings
    },
    {
      path: '/settings/server',
      name: 'Server Settings',
      component: RemoteConfig
    },
    {
      path: '/settings/import',
      name: 'Import Settings',
      component: ImportConfig
    },
    {
      path: '/change-event',
      name: 'Change event date & time',
      component: ChangeEvent
    },
    {
      path: '/set-event',
      name: 'Select event date & time',
      component: SetEvent
    }
  ]
})

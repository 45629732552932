import { openDB } from 'idb';
import SettingStore from "@/SettingStore";
import base64 from "base-64";

let eventEntry = {
    dbPromise : {},
    dbName: 'event-entry-store',
    entryData: 'event-entry',
    entryUpdates: 'update-queue',

    init: async function () {
        eventEntry.dbPromise = await openDB(eventEntry.dbName, 1, {
            upgrade(db) {
                db.createObjectStore(eventEntry.entryData, {keyPath: 'barcode'});
                db.createObjectStore(eventEntry.entryUpdates, {keyPath: 'timestamp'});
            },
        });
        SettingStore.init();
    },

    formatTicketInfo: async function(code, status) {
        if(typeof(status) === 'undefined') {
            status  = true;
        }
        let message = '';
        switch(code) {
            case 'N':
                message = 'Ticket has not be used yet.';
                break;
            case 'E':
                message = 'Entry has already been made on this ticket.';
                break;
            case 'X':
                message = 'Exit has already been made on this ticket.';
                break;
            case 'T':
                // Ticket was used to make a temporary exit
                message = 'Ticket used for temporary exit from event';
                break;
            default:
                // Unknown status
                message = 'Internal error. Ticket has been used already. Status ('+code+') unknown';
                status = false;
        }
        return {
            status: status,
            message: message
        }
    },
    getTicketInfo: async function(ticket_identifier) {
        return await eventEntry.getEntryStatus(ticket_identifier)
            .then( (entryInfo) => {
                if(typeof(entryInfo) === 'undefined') {
                    return {
                        status: false,
                        message: 'Ticket is not recognized for this event and date combination.'
                    };
                } else {
                    return eventEntry.formatTicketInfo(entryInfo.entry_status_code);
                }

            })
            .catch(function(error) {
                console.error("EventEntryStore::getTicketInfo err: "+error);
                return null;
            });
    },
    requestTicketEntry: async function(ticket_identifier) {
        return await eventEntry.getEntryStatus(ticket_identifier)
            .then( (entryInfo) => {
                if(typeof(entryInfo) === 'undefined') {
                    return {
                        status: false,
                        message: 'Ticket is not recognized for this event and date combination.'
                    };
                } else if(entryInfo.entry_status_code === 'N' || entryInfo.entry_status_code === 'T') {
                    return eventEntry.recordEntryStatusChange(
                        entryInfo,
                        'E',
                        "Entry recorded",
                        "Failed to record entry, technical error try again or contact support"
                    );
                } else {
                    return eventEntry.formatTicketInfo(entryInfo.entry_status_code, false);
                }
            })
            .catch(function(error) {
                console.error("EventEntryStore::requestTicketEntry err: "+error);
                return null;
            });
    },

    requestTicketExit: async function(ticket_identifier, temporaryExit) {
        return await eventEntry.getEntryStatus(ticket_identifier)
            .then( (entryInfo) => {
                if(typeof(entryInfo) === 'undefined') {
                    return {
                        status: false,
                        message: 'Ticket is not recognized for this event and date combination.'
                    };
                } else if(entryInfo.entry_status_code === 'E' || entryInfo.entry_status_code === 'T') {
                    const newStatus = typeof(temporaryExit) !== 'undefined' && temporaryExit ? 'T' : 'X';
                    return eventEntry.recordEntryStatusChange(
                        entryInfo,
                        newStatus,
                        "Exit recorded",
                        "Failed to record exit, technical error try again or contact support"
                    );
                } else {
                    return eventEntry.formatTicketInfo(entryInfo.entry_status_code, false);
                }
            })
            .catch(function(error) {
                console.error("EventEntryStore::requestTicketExit err: "+error);
                return null;
            });
    },

    recordEntryStatusChange: async function(entryInfo, newStatus, successMsg, failureMsg) {
        const tx = eventEntry.dbPromise.transaction([eventEntry.entryData, eventEntry.entryUpdates], 'readwrite');
        const txCacheStore = tx.objectStore(eventEntry.entryData);
        const txQueueStore = tx.objectStore(eventEntry.entryUpdates);
        entryInfo.entry_status_code = newStatus;
        let updateNotification = entryInfo;
        updateNotification.timestamp = new Date().getTime();
        return Promise.all ( [
            txCacheStore.put(entryInfo),
            txQueueStore.add(updateNotification)
        ])
        .then( () => {
            console.log('going to txCache.done');
            tx.done;
            return {
                status: true,
                message: successMsg
            }
        })
            .catch( (e) => {
                console.log('EventEntryStore::recordEntryStatusChange err: '+e);
                return {
                    status: false,
                    message: failureMsg
                }
            })
    },

    formatEntryStatus: function (code) {
        switch(code) {
            case 'N':
                return 'Not used';
            case 'E':
                return 'Entered';
            case 'T':
                return 'Temporary exit';
            case 'U':
                return 'Unknown';
            case 'X':
                return 'Exited';
            default:
                return 'Unknown'
        }
    },

    action: async function (requestVerb, data) {
        switch(requestVerb) {
            case 'ticket_info':
                // Note this will also check the event because entry data is pre-cached for an event/date combination
                return await eventEntry.getTicketInfo(data.ticket_identifier);
            case 'ticket_info_event':
                return await eventEntry.getTicketInfo(data.ticket_identifier);
            case 'ticket_entry':
                // Note this will also check the event because entry data is pre-cached for an event/date combination
                return await eventEntry.requestTicketEntry(data.ticket_identifier);
            case 'ticket_entry_event':
                return await eventEntry.requestTicketEntry(data.ticket_identifier);

            case 'ticket_exit':
                // Note this will also check the event because entry data is pre-cached for an event/date combination
                return await eventEntry.requestTicketExit(data.ticket_identifier);

            case 'ticket_exit_event':
                return await eventEntry.requestTicketExit(data.ticket_identifier);

            case 'ticket_temp_exit':
                // Note this will also check the event because entry data is pre-cached for an event/date combination
                return await eventEntry.requestTicketExit(data.ticket_identifier, true);

            case 'ticket_temp_exit_event':
                return await eventEntry.requestTicketExit(data.ticket_identifier, true);
        }
    },

    fetchEntryData: async function(apiUrl, entity_id, event_id, entry_date) {
        const url = apiUrl + 'event/entity_id='+entity_id+'/event_id='+event_id+'/date='+entry_date;
        console.log('EventEntryStore::fetchEntryData url: '+url);
        let username = SettingStore.getUsername();  // *** trying not to use setting store in case service worker is used
        let password = SettingStore.getPassword();

        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));

        return await fetch(url, {
            method: 'get',
            headers: headers
        })
            .then(response => {
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                return response;
            })
            .then(response => response.json())
            .then(function(json) {
                    console.log(json);
                    const tx = eventEntry.dbPromise.transaction(eventEntry.entryData, 'readwrite');
                    tx.store.clear(eventEntry.entryData);
                    Promise.all (
                        json.map((row) => {
                            tx.store.add(row);
                        })
                    ).then( () => {tx.done;})
                }
            )
            .catch(function(error) {
                console.error("EventEntryStore::fetchEntryData err: "+error);
                return null;
            })
    },

    clearAllEntryData: async function() {
        return (await dbPromise).clear(eventEntry.entryData);
    },

    safeSendEntryUpdates: async function() {
        // send entry updates if config is sufficient and then only if there are updates
        if(SettingStore.getApiUri() &&
            SettingStore.getUsername() &&
            SettingStore.getPassword() &&
            SettingStore.getEntityId() &&
            SettingStore.getEventId() &&
            SettingStore.getEventDate()
        ) {
            return eventEntry.nonZeroSendEntryUpdates();
        } else {
            return null;
        }
    },

    nonZeroSendEntryUpdates: async function() {
        // sent entry updates if there are any
        return await eventEntry.countEntryUpdates()
            .then( (updateCount) => {
                if(updateCount) {
                    return eventEntry.sendEntryUpdates();
                } else {
                    return null;
                }
            });
    },

    sendEntryUpdates: async function() {
        // get any outstanding queued updates
        const updates = await eventEntry.dbPromise.getAll(eventEntry.entryUpdates);
        console.log('EventEntryStore::sendEntryUpdates got updates: '+JSON.stringify(updates,null,4));

        // send to server
        eventEntry.postEntryUpdates(updates)
            .then(function ( postResult) {
                if( ! postResult.status) {
                    console.error("EventEntryStore::fetchEntryData failed sending updates with err: "+postResult.status);
                    return null;
                }
                // extract id of each update sent
                const ids = updates.map( (u) => { return u.timestamp; });

                // remove all queue entries that have now been sent
                console.log('EventEntryStore::sendEntryUpdates ids: '+JSON.stringify(ids));
                const tx = eventEntry.dbPromise.transaction(eventEntry.entryUpdates, 'readwrite');
                Promise.all (
                    ids.map((id) => {
                        tx.store.delete(id);
                    })
                ).then( () => {
                    tx.done;
                    console.log('EventEntryStore::sendEntryUpdates removed '+ids.length+' updates from queue');
                })
                    .catch(function(error) {
                        console.error("EventEntryStore::fetchEntryData failed with db err: "+error);
                        return null;
                    });
                }
            )
            .catch(function(error) {
                console.error("EventEntryStore::fetchEntryData failed sending updates err: "+error);
                return null;
            });
    },

    postEntryUpdates: async function(updates) {
        const entity_id = SettingStore.getEntityId();
        const event_id = SettingStore.getEventId();
        const entry_date = SettingStore.getEventDate();
        const url = SettingStore.getApiUri() + 'event/entity_id='+entity_id+'/event_id='+event_id+'/date='+entry_date;
        console.log('EventEntryStore::postEntryUpdates url: '+url);
        let username = SettingStore.getUsername();
        let password = SettingStore.getPassword();

        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));
        headers.append('Content-Type', 'application/json');

        return await fetch(url, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(updates)
        })
            .then(response => {
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                return response;
            })
            .then(response => response.json())
            .catch(function(error) {
                console.error("EventEntryStore::postEntryUpdates err: "+error);
                return null;
            })
    },

    getEntryStatus: async function (key) {
        console.log('EventEntryStore::getEntryStatus key: '+key);
        return (await eventEntry.dbPromise).get(eventEntry.entryData, key);
    },
    addEntryStatus: async function (key, val) {
        return (await eventEntry.dbPromise).put(eventEntry.entryData, val, key);
    },
    delEntryStatus: async function (key) {
        return (await eventEntry.dbPromise).delete(eventEntry.entryData, key);
    },
    indexEntryStatus: async function () {
        return (await eventEntry.dbPromise).getAllKeys(eventEntry.entryData);
    },
    countEntryDataBis: async function () {
        const tx = eventEntry.dbPromise.transaction(eventEntry.entryData, 'readonly');
        const txCacheStore = tx.objectStore(eventEntry.entryData);
        return txCacheStore.count()
        .then( (total) => {
                console.log('count going to txCache.done total: '+total);
                tx.done;
                return total;
            })
            .catch( (e) => {
                console.log('EventEntryStore::recordEntryStatusChange err: '+e);
                return {
                    status: false,
                    message: failureMsg
                }
            })
    },
    countEntryData: async function () {
        return (await eventEntry.dbPromise).count(eventEntry.entryData);
    },

    getEntryUpdate: async function (key) {
        return (await eventEntry.dbPromise).get(eventEntry.entryUpdates, key);
    },
    setEntryUpdate: async function (key, val) {
        return (await eventEntry.dbPromise).put(eventEntry.entryUpdates, val, key);
    },
    delEntryUpdate: async function (key) {
        return (await eventEntry.dbPromise).delete(eventEntry.entryUpdates, key);
    },
    indexEntryUpdates: async function () {
        return (await eventEntry.dbPromise).getAllKeys(eventEntry.entryUpdates);
    },
    countEntryUpdates: async function () {
        return (await eventEntry.dbPromise).count(eventEntry.entryUpdates);
    },
    getStatus: async function () {
        let dataCount = 0;
        let updateCount = 0;
        await eventEntry.countEntryDataBis().then( (x) => {  dataCount = x;} );
        await eventEntry.countEntryUpdates().then( (x) => {  updateCount =  x;} );
        console.log('eventEntry::getStatus #data: '+dataCount+' updates: '+updateCount);
        return [
            { name: 'Cached ticket count', value: dataCount},
            { name: 'Entry updates waiting', value: updateCount}
        ];
    }
};

export default eventEntry;
<template>
  <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
    <md-card-header>
      <div class="md-title">Import Downloaded Settings</div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-small-size-100">
          <p>Select the config file that you downloaded from Coconut Tickets website</p>
          <p>By default the file is named <b>coconut_tickets_scan.cfg</b> be careful to select the latest version</p>
          <md-field>
            <md-file v-model="filename" v-on:change="loadTextFromFile" accept=".cfg">Select configuration file to import</md-file>
          </md-field>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
name: "ConfigFileSelector",
  data: function() {
    return {
      filename: '',
    }
  },
  methods: {
    fileSelected: function (fileEvent) {
      console.log('ConfigFileSelector::fileSelected name: '+JSON.stringify(this.filename,null,4)+'fileEv: '+JSON.stringify(fileEvent,null,4));
      this.$emit('fileselected', this.filename);
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = e => this.$emit("load", e.target.result);
      reader.readAsText(file);
    }
  }
}
</script>

<style scoped>

</style>
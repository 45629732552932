<template>
  <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
    <md-card-header>
      <div class="md-title">Settings not complete</div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout md-gutter">
        <p v-html="configMessage">
        <div class="md-layout-item md-size-100 ">
          <md-button class="md-primary md-raised md-alignment-center-center" type="button" @click="goToSettings">
            <md-icon>settings</md-icon>
            {{settingLabel}}
          </md-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
name: "MissingConfig",
  props: {
    missing_config: {
      type: Boolean,
      required: true
    },
    missing_event: {
      type: Boolean,
      required: true
    },
  },
  data: function () {
    return {
      configMessage: '',
      settingLabel: '',
      settingRoute: '',
    }
  },
  methods: {
    setMessage: function() {
      if(this.missing_event) {
        this.configMessage = "Before scanning tickets please select the event and the day of event entry.";
        this.settingRoute = '/change-event';
        this.settingLabel = 'Set event and date';
      }
      if(this.missing_config) {
        this.configMessage = "The scanner has not been configured. Please load the config from Coconut Tickets using the QR code";
        this.settingRoute = '/settings/server';
        this.settingLabel = 'Scan settings from QR';
      }
    },
    goToSettings: function() {
      this.$router.push(this.settingRoute);
    }
  },
  mounted() {
    this.setMessage();
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <h1>Import Configuration</h1>
    <config-file-selector v-if="isScanning" v-on:load="fileSelected"></config-file-selector>
    <display-scan-result v-if=" ! isScanning" v-on:closed="nextScan" :result="result"></display-scan-result>
  </div>
</template>

<style scoped>
h1 {

}
</style>

<script>
import QrTicketScanner from "@/components/QrTicketScanner";
import DisplayScanResult from "@/components/DisplayScanResult";
import SettingStore from "@/SettingStore";
import ConfigFileSelector from "@/components/ConfigFileSelector";

export default {
  components: {DisplayScanResult, ConfigFileSelector},
  data : function() {
    return {
      isScanning: true,
      result: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    fileSelected: function(configData) {
      console.log('ImportConfig::fileSelected received content: '+configData);
      SettingStore.loadExternalConfig(configData)
      .then( result =>  {
        console.log('ImportConfig::fileSelected got back: '+JSON.stringify(result,null,4));
        if(result) {
          this.result = {
            status: true,
            message: "Successfully loaded app configuration"
          }
        } else {
          this.result = {
            status: false,
            message: "Failed to get app configuration. Try again or contact technical support."
          }
        }
        this.isScanning = false;
      }).catch(e => console.error(e));
    },
    nextScan: function() {
      if(this.result.status) {
        this.$router.push('/'); // config settings imported, move on to ticket scanning
      } else {
        // failed to import settings, try again
        this.isScanning = true;
        this.clearResult();
      }
    },
    clearResult: function() {
      this.result = {
        status: false,
        message: ''
      };
    }
  },
  mounted() {
    SettingStore.init();
  }
}
</script>

<template>
  <div>
    <div v-if="missingConfig || missingEvent">
      <missing-config :missing_config="missingConfig" :missing_event="missingEvent"></missing-config>
    </div>
    <div v-else>
      <div v-if=" ! choosingAction" class="md-layout md-gutter">
        <md-button type="button"  @click="changeScanAction" class="">Change action</md-button>
        <p>{{scanActionFormatted}}</p>
      </div>
      <scan-action v-if="choosingAction" v-on:selected="scanActionSelected"></scan-action>
      <qr-ticket-scanner v-if="isScanning" v-on:foundticket="ticketFound"></qr-ticket-scanner>
      <display-scan-result v-if=" ! isScanning && ! choosingAction" v-on:closed="nextScan" :result="result"></display-scan-result>
    </div>
  </div>
</template>

<style scoped>
h1 {
  color: green
}
</style>

<script>
import QrTicketScanner from "@/components/QrTicketScanner";
import TicketResources from "@/TicketResources";
import DisplayScanResult from "@/components/DisplayScanResult";
import ScanAction from "@/components/ScanAction";
import SettingStore from "@/SettingStore";
import MissingConfig from "@/components/MissingConfig";
import eventEntry from "@/EventEntryStore";

export default {
  components: {MissingConfig, ScanAction, DisplayScanResult, QrTicketScanner},
  data : function() {
    return {
      missingConfig: false,
      missingEvent: false,
      isScanning: false,
      choosingAction: true,
      scanAction: '',
      result: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    changeScanAction: function() {
      this.choosingAction = true;
      this.isScanning = false;
    },
    scanActionSelected: function(action) {
      this.scanAction = action;
      this.choosingAction = false;
      this.isScanning = true;
    },
    ticketFound: function(identifier) {
      TicketResources.action(
          this.scanAction,
          {
            ticket_identifier: identifier,
            event_id: SettingStore.getEventId(),
            date: SettingStore.getEventDate()
          })
      .then( result =>  {
        console.log('ticketFound got back: '+JSON.stringify(result,null,4));
        if(result) {
          this.result = result;
        } else {
          this.result = {
            status: false,
            message: 'Technical error. Check that a Coconut Tickets QR code is being scanned. Please try again or contact technical support.'
          }
        }
        this.isScanning = false;
      }).catch(e => {
        console.log(e);
        this.result = {
          status: false,
          message: 'Technical fault. Please try again or contact technical support. '+e
        }
      });
    },
    nextScan: function() {
      this.isScanning = true;
      this.clearResult();
    },
    clearResult: function() {
      this.result = {
        status: false,
        message: ''
      };
    },
    validateConfig: function () {
      this.missingConfig = ! SettingStore.hasEntityConfig();
      this.missingEvent = ! SettingStore.hasEventConfig();
    },
    fetchEntryData: async function() {
      await eventEntry.fetchEntryData(
          SettingStore.getApiUri(),
          SettingStore.getEntityId(),
          SettingStore.getEventId(),
          SettingStore.getEventDate()
      );
    }
  },
  mounted() {
    TicketResources.init();
    SettingStore.init();
    this.validateConfig();
    eventEntry.init()
        .then( eventEntry.safeSendEntryUpdates)
        .then( () => {
          if( ! this.missingConfig && ! this.missingEvent) {
            this.fetchEntryData();
          }
        });
  },
  computed: {
    scanActionFormatted: function() {
      return TicketResources.mapRequestVerbToLabel(this.scanAction);
    }
  }
}
</script>

<template>
  <div class="mdl-layout__content">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone">
        <md-card>
          <md-table>
            <md-table-toolbar>
              <h1 class="md-title">Current Settings</h1>
            </md-table-toolbar>

            <md-table-row>
              <md-table-head>Setting</md-table-head>
              <md-table-head>Value</md-table-head>
            </md-table-row>

            <md-table-row v-for="item in settingsList" v-bind:key="item.name">
              <md-table-cell>{{item.name}}</md-table-cell>
              <md-table-cell>{{item.value}}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card>
      </div>
      <md-button @click="goBack" class="md-raised md-primary v-distance">Back to settings</md-button>
    </div>

  </div>
</template>

<style scoped>
h1 {
  color: green
}
.v-distance {
  margin-top: 10px;
}
</style>

<script>
import SettingStore from "@/SettingStore";
import eventEntry from "@/EventEntryStore";

export default {
  components: {},
  data : function() {
    return {
      settingsList: [],
      result: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    goBack: function() {
      this.$router.push('/settings');
    },
    buildView: function() {
      this.settingsList = SettingStore.formatAllSettings();
      eventEntry.getStatus()
      .then( (status) => {
        console.log('ViewSettings::buildView status:'+JSON.stringify(status,null,4));
        this.settingsList.push(status[0]);
        this.settingsList.push(status[1]);
      });
    },
  },
  mounted() {
    SettingStore.init();
    eventEntry.init()
    .then( () => { this.buildView();});
  }
}
</script>

import SettingStore from "@/SettingStore";
import base64 from 'base-64';
import eventEntry from "@/EventEntryStore";
let validation = {
    init: function() {
        SettingStore.init();
        eventEntry.init();
    },
    requestVerbMapping: {
        'ticket_info': 'Ticket information',
        'ticket_info_event': 'Ticket information for the selected event',
        'ticket_entry': 'Event entry',
        'ticket_entry_event': 'Entry to the selected event',
        'ticket_exit': 'Exit an event (also checking already entered)',
        'ticket_exit_event': 'Exit the selected event (also checking already entered)',
        'ticket_temp_exit': 'Temporary exit from an event',
        'ticket_temp_exit_event': 'Temporary exit from the selected event',
        'event_list': 'List all events'
    },
    mapRequestVerbToLabel: function(requestVerb) {
        if( typeof(validation.requestVerbMapping[requestVerb]) !== 'undefined') {
            return validation.requestVerbMapping[requestVerb]
        } else {
            return '<Request unknown>';
        }
    },
    getTicketInformation: async function(config) {
        return await this.getFromServer(
            'ticket_info', config
        ).then( (x) => { console.log('getTicketInformation: '+JSON.stringify(x,null,4)); return x;});
    },
    action: async function(requestVerb, config) {
        if(SettingStore.isCachingEventEntryData()) {
            return await eventEntry.action(
                requestVerb, config
            ).then( (x) => { console.log('eventEntry::validate::'+requestVerb+': '+JSON.stringify(x,null,4)); return x;});
        } else {
            return await this.getFromServer(
                requestVerb, config
            ).then( (x) => { console.log('validate::'+requestVerb+': '+JSON.stringify(x,null,4)); return x;});
        }
    },
    formatHttpQuery: function(params) {
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return query;
    },
    makeRequestUri: function(requestVerb, config) {
        let url = SettingStore.getApiUri();
        let method = 'GET';

        switch(requestVerb) {
            case 'ticket_info':
                url += 'ticket/barcode='+config.ticket_identifier+'/date='+config.date;
                break;
            case 'ticket_info_event':
                url += 'ticket/barcode='+config.ticket_identifier+'/date='+config.date + '/event_id='+config.event_id;
                break;
            case 'ticket_entry':
                url += 'ticket_entry/barcode='+config.ticket_identifier+'/date='+config.date;
                method = 'POST';
                break;
            case 'ticket_entry_event':
                url += 'ticket_entry/barcode='+config.ticket_identifier+'/date='+config.date + '/event_id='+config.event_id;
                method = 'POST';
                break
            case 'ticket_exit':
                url += 'ticket_exit/barcode='+config.ticket_identifier+'/date='+config.date;
                method = 'POST';
                break;
            case 'ticket_exit_event':
                url += 'ticket_exit/barcode='+config.ticket_identifier+'/date='+config.date + '/event_id='+config.event_id;
                method = 'POST';
                break
            case 'ticket_temp_exit':
                url += 'ticket_temp_exit/barcode='+config.ticket_identifier+'/date='+config.date;
                method = 'POST';
                break;
            case 'ticket_temp_exit_event':
                url += 'ticket_temp_exit/barcode='+config.ticket_identifier+'/date='+config.date + '/event_id='+config.event_id;
                method = 'POST';
                break
            case 'event_list':
                url += 'events/entity_id='+SettingStore.getEntityId();
                break;
            default:
                console.error('TicketValidation::makeRequestUri Unknown requestVerb: '+requestVerb);
                break
        }
        return {
            url: url,
            method: method
        };
    },
     getFromServer: async function(requestVerb, config) {

        const netResource = this.makeRequestUri(requestVerb, config);
        console.log('TicketValidation::getFromServer url: '+netResource.url+' method: '+netResource.method);
        let username = SettingStore.getUsername();
        let password = SettingStore.getPassword();

        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));

        return await fetch(netResource.url, {
            method: netResource.method,
            headers: headers
        })
            .then(response => {
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                return response;
            })
            .then(response => response.json())
            .then(function(json) {
                    console.log(json);
                    return json;
                }
            )
            .catch(function(error) {
                console.error("ticketValidation::getFromServer err: "+error);
                return null;
            })
    }
};

export default validation;

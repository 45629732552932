<template>
  <div>
    <div :class="resultBannerClass">
      <div class="md-layout md-alignment-center-center">
        <md-icon class="md-size-5x">{{bannerIcon}}</md-icon>
      </div>
    </div>
    <p class="md-alignment-center" v-html="result.message"></p>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100">
        <md-button class="md-raised md-primary" type="button" v-on:click="next">{{buttonText}}</md-button>
      </div>
    </div>
  </div>

</template>

<script>
import effects from "@/SoundEffects";
import settings from "@/SettingStore";
import eventEntry from "@/EventEntryStore";
export default {
  name: "DisplayScanResult",
  props: {
    result: {
      type: Object,
      required: true
    },
  },
  data: function() {
    return {
      resultBannerClass: '',
      buttonText: '',
      bannerText: '',
      bannerIcon: ''
    }
  },
  methods: {
    next: function() {
      this.$emit('closed');
    }
  },
  mounted() {
    settings.init();
    if(this.result.status) {
      this.resultBannerClass = 'successBanner';
      this.bannerText = 'success';
      this.bannerIcon = 'check_circle';
      this.buttonText = "Next";
      if(settings.isAudible()) {
        effects.playSuccess()
      }
    } else {
      this.resultBannerClass = 'failureBanner';
      this.bannerText = 'failed';
      this.bannerIcon = 'error';
      this.buttonText = "Try again";
      if(settings.isAudible()) {
        effects.playFailure()
      }
    }
    eventEntry.safeSendEntryUpdates();
  }
}
</script>

<style scoped>
  .successBanner {
    width: 100%;
    background-color: green;
    height: 30vh;
  }

  .failureBanner {
    width: 100%;
    background-color: red;
    height: 30vh;
  }
  button {
    width: 100%;
  }
  .md-icon {
    color: white !important;
  }
</style>
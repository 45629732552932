<template>
  <div>
    <h1>Select event and date of entry</h1>
    <event-settings :entity_id="entity_id" :event_id="event_id" :event_date="event_date" v-on:save-event="saveEvent"></event-settings>
    <p>Save your selection to move to the next section</p>
  </div>
</template>

<style scoped>
h1 {
  color: black
}
</style>

<script>
import SettingStore from "@/SettingStore";
import EventSettings from "@/components/EventSettings";

export default {
  components: {EventSettings},
  data : function() {
    return {
      entity_id: 0,
      event_id: 0,
      event_date: '',
      time_slot: '',
    }
  },
  methods: {
    next: function() {
      this.$router.push('/scan');
    },

    saveEvent: function(settings) {
      SettingStore.setEventId(settings.selected_event_id);
      this.event_id = settings.selected_event_id;
      SettingStore.setEventName(settings.selected_event_name);
      this.event_name = settings.selected_event_name;
      SettingStore.setEventDate(settings.selected_event_date);
      this.event_date = settings.selected_event_date;
      SettingStore.setEventTimeSlot(settings.selected_time_slot);
      this.time_slot = settings.selected_time_slot;
      SettingStore.saveConfig();
      this.next();
    }

  },
  mounted() {
    SettingStore.init();
    this.entity_id = SettingStore.getEntityId();
    this.event_id = SettingStore.getEventId();
    this.event_date = SettingStore.getEventDate();
  },
  computed: {
    isEventSetup: function () {
      return this.entity_id && this.event_id && this.event_date;
    },
  }
}
</script>

<template>
  <div>
    <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
      <md-card-header>
        <div class="md-title">Event Settings</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="event_id">Validate tickets for which event?</label>
              <md-select v-model="selectedEventId" @md-selected="eventChanged" name="event_id" id="event_id" required>
                <md-option v-for="e in eventList" :value="e.event_id" v-bind:key="e.event_id">{{e.event_name}}</md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="event_date">Entry date?</label>
              <md-select v-model="dateSelected" name="event_date" id="event_date" required>
                <md-option v-for="d in eventDateList" :value="d.code" v-bind:key="d.code">{{d.name}}</md-option>
              </md-select>
              <div v-if="todayIsValid">
                <md-button type="button" class="md-raised" @click="setToday">Today</md-button>
              </div>
            </md-field>
          </div>

          <div v-if="hasTimeSlots" class="md-layout-item md-small-size-100 time-slot-control">
            <md-field>
              <label>Which time slot are they arriving for?</label>
              <md-select v-model="timeSlotSelected">
                <md-option v-for="t in timeSlotList" :value="t.code" v-bind:key="t.code">{{t.name}}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div class="md-layout control-area">
      <div class="md-layout-item md-size-50">
        <md-button type="button" @click="back" class="md-raised">Back</md-button>
      </div>
      <div class="md-layout-item md-size-50">
        <md-button :disabled=" ! isEventSetup" type="button" @click="requestSave" class="md-raised md-primary">Save</md-button>
      </div>
    </div>
    <div v-if="errors">
      <p v-for="error in errors" v-html="error"></p>
    </div>
  </div>
</template>

<script>
import SettingStore from "@/SettingStore";
import TicketResources from "@/TicketResources";
import base64 from 'base-64';

export default {
  name: "EventSettings",
  props: {
    entity_id: {
      type: [Number,String],
      required: true
    },
    event_id: {
      type: [Number,String],
      required: true
    },
    event_date: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      selectedEventId: '',
      selectedEventName: '',
      eventList: [],
      dateSelected: '',
      dateToday: '',
      eventDateList: [],
      timeSlotSelected: '',
      hasTimeSlots: false,
      errors: []
    }
  },
  methods: {
    setToday: function() {
      this.dateSelected = this.dateToday;
    },
    back: function() {
      this.$router.push('/settings');
    },
    requestSave: function() {
      if(this.validateFields()) {
        this.saveSettings();
      }
    },
    saveSettings: function() {
      const settings = {
        selected_event_id: this.selectedEventId,
        selected_event_name: this.selectedEventName,
        selected_event_date: this.dateSelected,
        selected_time_slot: this.timeSlotSelected
      };
      this.$emit('save-event', settings);
    },
    isMatchingEventId: function(e) {
      return e.event_id === this.selectedEventId;
    },
    findSelectedEventName: function() {
      if(this.eventList.length > 0) {
        console.log('selectedEventId: '+this.selectedEventId);
        const eventResult = this.eventList.find(this.isMatchingEventId);
        console.log('eventResult: '+JSON.stringify(eventResult, null, 4));
        if(typeof(eventResult) !== 'undefined') {
          return eventResult.event_name;
        }
      }
      return '';
    },
    eventChanged: async function() {
      this.selectedEventName = this.findSelectedEventName();
      this.getEventData(this.selectedEventId)
      .then( result => {
        if(Array.isArray(result)) {
          this.eventDateList = result;
          if( ! this.dateSelected) {
            this.dateSelected = this.event_date;
          }
        }
      });
    },
    getEvents: function() {
      TicketResources.getFromServer('event_list', null)
      .then( result => {
        this.eventList = result;
        if( ! this.selectedEventId) {
          this.selectedEventId = this.event_id;
        }
      })
      .catch( err => {
        console.error(err);
      })
    },
    getEventData: async function(event_id) {
      // This data is only used to make selections, after which it is discarded
      let url = SettingStore.getApiUri();
      url += 'event/event_id='+event_id+'/active_schedule';

      console.log('EventSettings::getEventData url: '+url);
      let username = SettingStore.getUsername();
      let password = SettingStore.getPassword();

      let headers = new Headers();
      headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));

      return fetch(url, {
        method:'GET',
        headers: headers
      })
          .then(response => {
            if (!response.ok) {
              const message = `An error has occurred: ${response.status}`;
              throw new Error(message);
            }
            return response;
          })
          .then(response => response.json())
          .then(function(json) {
                console.log(json);
                return json;
              }
          )
          .catch(function(error) {
            console.error("EventSettings::getEventData err: "+error);
          })
    },
    validateFields: function() {
      this.errors = [];
      if( ! this.selectedEventId) {
        this.errors.push("Please select the event for which you wish to scan tickets");
      }
      if( ! this.dateSelected) {
        this.errors.push("Please select the entry date to scan tickets for - it must be one of the event dates");
      }
      return this.errors.length === 0;
    },
    getDateCode: function(d) {
      return d.code;
    }
  },
  computed: {
    timeSlotList: function () {
      return [];  // TO DO return time slots relevant to the event date if the event supports time slots and this is a choice
    },
    todayIsValid: function () {
      // Only allow today to be valid if it is among the event schedule dates
      return this.eventDateList.map(this.getDateCode).includes(this.dateToday);
    },
    isEventSetup: function () {
      return this.entity_id && this.selectedEventId && this.dateSelected;
    },
  },
  mounted() {
    TicketResources.init();
    SettingStore.init();
    this.getEvents();
    this.dateToday = new Date().toISOString().substr(0,10);
  },
  watch: {
    event_id: {
      deep: false,
      immediate: true,
      handler() {
        this.selectedEventId = this.event_id;
      }
    },
    event_date: {
      deep: false,
      immediate: true,
      handler() {
        this.dateSelected = this.event_date;
        if(this.event_id && this.event_date) {
          this.selectedEventId = this.event_id
          this.eventChanged();  // date already selected so we need to get the list of dates to display
        }
      }
    }
  }
}
</script>

<style scoped>
  .settings-card {
    max-width: 600px;
  }
  .control-area {
    margin-top: 25px;
  }
  button {
    width: 90%;
  }
</style>
<template>
  <div class="page-container">
    <md-app md-waterfall md-side-drawer md-layout-row md-mode="fixed">
      <md-app-toolbar class="md-primary">
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start">
            <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
              <md-icon>menu</md-icon>
            </md-button>
          </div>

          <div class="md-toolbar-section-end">
            <!-- empty -->
          </div>
        </div>

        <div class="md-toolbar-row md-toolbar-offset">
          <img class="ct-logo" src="coconut-tickets-logo-300x76.png">
        </div>
      </md-app-toolbar>

      <md-app-drawer md-fixed md-left :md-active.sync="menuVisible">
        <md-toolbar class="md-transparent" md-elevation="0">Ticket scanner menu</md-toolbar>

        <md-list>
          <md-list-item v-for="(link, index) in links" :key="index">
            <router-link class="mdl-navigation__link" :to="link.to">
              <div class="md-list-item-content md-ripple">
              <slot>
                <md-icon>{{ link.icon }}</md-icon>
                <p>{{ link.name }}</p>
              </slot>
              </div>
            </router-link>
          </md-list-item>
        </md-list>

      </md-app-drawer>

      <md-app-content>
        <router-view />
      </md-app-content>
    </md-app>
  </div>



</template>

<style scoped>
.md-app {
  border: none;
}

.md-app-content {
  background-color: gainsboro !important;
  min-height: 600px !important;
}

.md-app-toolbar {
  height: 100px;
}

.ct-logo {
  max-height: 45px;
  padding-bottom: 5px;
}

</style>

<script>

//import SideBar from "@/components/SideBar";
export default {
  components: {
    //SideBar
  // MdApp, MdContent, MdList
  },
  data: () => ({
    menuVisible: false,
    links: [
      {
        name: 'Home',
        to: '/',
        icon: 'home'
      },
      {
        name: 'Scan tickets',
        to: '/scan',
        icon: 'qr_code_scanner'
      },
      {
        name: 'Settings',
        to: '/settings',
        icon: 'settings'
      },
    ]
  }),
  watch: {
    '$route' () {
      this.menuVisible = false
    }
  }
}
</script>

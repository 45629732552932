<template>
  <div>
    <md-card class="md-layout-item md-size-50 md-small-size-100 settings-card">
      <md-card-header>
        <div class="md-title">Home</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100">
            <md-button class="md-raised md-primary" :disabled=" ! isEventSetup" type="button" v-on:click="startScanning">
              <md-icon>qr_code</md-icon>
              Scan Tickets
            </md-button>
          </div>
          <div v-if=" ! isConfigSetup" class="md-layout-item md-size-100">
            <p>Your application settings are incomplete.</p>
            <p>Get your settings QR code from the scanner profile page on Coconut Tickets and click button below to scan</p>
            <md-button class="md-raised md-primary" @click="scanConfig">Scan settings</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<style scoped>
h1 {
  color: blue
}
button {
  width: 100%;
}
</style>

<script>
import SettingStore from "@/SettingStore";
export default {
  name: "Home",
  methods: {
    isEventSetup: function () {
      return SettingStore.getEntityId() && SettingStore.getEventId() && SettingStore.getEventDate();
    },
    isConfigSetup: function () {
      return SettingStore.getEntityId() && SettingStore.getApiUri();
    },
    scanConfig: function () {
      this.$router.push('/settings/server');
    },
    startScanning: function () {
      if(this.isEventSetup()) {
        this.$router.push('/scan');
      } else {
        this.$router.push('/set-event');
      }
    },
  },
  mounted() {
    SettingStore.init();
  }
}
</script>

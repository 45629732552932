<template>
  <div>
    <qrcode-stream :key="_uid" :track="true" @decode="onDecode" @init="logErrors" />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
name: "QrTicketScanner",
  components: { QrcodeStream },
  data () {

    return { result: null }
  },

  methods: {
    onDecode (result) {
      this.result = result;
      this.$emit('foundticket', result);
    },

    logErrors (promise) {
      promise.catch(console.error)
    }
  }
}
</script>

<style scoped>

</style>
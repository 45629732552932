
let settings = {
    init: () => {
        settings.readConfig();
    },
    config: {
        loaded: false,
        audible_warnings: true,
        username: '<notset>',
        password: '<notset>',
        entity_id: 2,
        api_uri: '<notset>',
        dev_proxy_url: 'http://dev.scanct/api/get_config',
        prod_proxy_url: 'https://ctblog.cliveverrall.com/api/get_config',
        selected_event_date: '',
        selected_time_slot: '',
        selected_event_id: '',
        selected_event_name: '',
        cache_event_entry_data: true
    },
    config_defaults: {
        loaded: false,
        audible_warnings: true,
        username: '',
        password: '',
        entity_id: 0,
        api_uri: '',
        dev_proxy_url: 'http://dev.scanct/api/get_config',
        prod_proxy_url: 'http://dev.scanct/api/get_config',
        selected_event_date: '',
        selected_time_slot: '',
        selected_event_id: '',
        selected_event_name: '',
        cache_event_entry_data: true
    },
    config_labels: {
        audible_warnings: "Audible warnings",
        username: 'User name',
        password: 'Password',
        entity_id: 'Organizer',
        api_uri: 'API URI',
        dev_proxy_url: 'Proxy 1',
        prod_proxy_url: 'Proxy 2',
        selected_event_date: 'Date of event entry',
        selected_time_slot: 'Time slot for event entry',
        selected_event_id: 'Event ID',
        selected_event_name: 'Event name',
        cache_event_entry_data: 'Cache event entry data'
    },
    isConfigLoaded: () => {
        return settings.config.loaded;
    },
    saveConfig: () => {
        localStorage.setItem("config", JSON.stringify(settings.config));
    },
    mergeWithDefaults: (config) => {
        let newConfig = JSON.parse(JSON.stringify(config,null,4));
        const keys = Object.keys(settings.config_defaults);
        for( let key =0; key < keys.length; key++  ) {
            // if a key is missing then add it and set the default value
            if(typeof(newConfig[keys[key]]) === 'undefined') {
                console.log('merging with: '+keys[key]);
                newConfig[keys[key]] = settings.config_defaults[keys[key]];
            }
        }
        // console.log('mergeWithDefaults output: '+JSON.stringify(newConfig,null,4))
        return newConfig;
    },
    readConfig: () => {
        const value = localStorage.getItem("config");
        if(value) {
            settings.setConfigFromObject(JSON.parse(value));
            settings.config.loaded = true;
        } else {
            return false;
        }
    },
    getConfig: () => {
        if( ! settings.isConfigLoaded()) {
            if( ! settings.readConfig()) {
                console.error('SettingStore::getConfig failed to readConfig from localStorage');
            }
        }
        return settings.config;
    },
    isAudible: () => {
        return settings.config.audible_warnings;
    },
    getUsername: () => {
        return settings.config.username;
    },
    getPassword: () => {
        return settings.config.password;
    },
    getEntityId: () => {
        return settings.config.entity_id;
    },
    getApiUri: () => {
        return settings.config.api_uri;
    },
    getEventId: () => {
        return settings.config.selected_event_id;
    },
    getEventName: () => {
        return settings.config.selected_event_name;
    },
    getEventDate: () => {
        return settings.config.selected_event_date;
    },
    getEventTimeSlot: () => {
        return settings.config.selected_time_slot;
    },
    isCachingEventEntryData: () => {
        return settings.config.cache_event_entry_data;
    },
    setAudible: (status) => {
        settings.config.audible_warnings = status;
    },
    setUsername: (username) => {
        settings.config.username = username;
    },
    setPassword: (password) => {
        settings.config.password = password;
    },
    setEntityId: (entity_id) => {
        settings.config.entity_id = entity_id;
    },
    setApiUri: (uri) => {
        settings.config.api_uri = uri;
    },
    setEventId: (id) => {
        settings.config.selected_event_id = id;
    },
    setEventName: (name) => {
        settings.config.selected_event_name = name;
    },
    setEventDate: (event_date) => {
        settings.config.selected_event_date = event_date;
    },
    setEventTimeSlot: ( time_slot) => {
        settings.config.selected_time_slot = time_slot;
    },
    setCachingEventEntryData: ( status ) => {
        settings.config.cache_event_entry_data = status;
    },
    getDevProxyUrl: () => {
        return settings.config.dev_proxy_url;
    },
    getProdProxyUrl: () => {
        return settings.config.prod_proxy_url;
    },
    formatKey: (key) => {
        if(typeof(settings.config_labels[key]) !== 'undefined') {
            return settings.config_labels[key]
        } else {
            return '';
        }
    },
    isKeyForDisplay: (key) => {
        const notForDisplay = ['loaded'];
        return ! notForDisplay.includes(key)
    },
    formatAllSettings: () => {
        const keys = Object.keys(settings.config);
        let formattedConfig = [];
        for( let key =0; key < keys.length; key++  ) {
            // if a key is missing skip it
            if(typeof(settings.config[keys[key]]) !== 'undefined' && settings.isKeyForDisplay(keys[key])) {
                formattedConfig.push( {
                        name: settings.formatKey(keys[key]),
                        value: settings.config[keys[key]]
                });
            }
        }
        return formattedConfig;
    },
    mapRemoteConfigToLocal: (remoteJson)  => {
        return {
            username: remoteJson.loginName,
            password: remoteJson.password,
            entity_id: remoteJson.entity_id,
            api_uri: remoteJson.restUrl,
        };
    },
    setConfigFromObject: (config) => {
        let mergedConfig = settings.mergeWithDefaults(config);
        settings.setUsername(mergedConfig.username);
        settings.setPassword(mergedConfig.password);
        settings.setEntityId(mergedConfig.entity_id);
        settings.setApiUri(mergedConfig.api_uri);
        if(typeof(mergedConfig.selected_event_id) !== 'undefined' && mergedConfig.selected_event_id) {
            settings.setEventId(mergedConfig.selected_event_id);
        }
        if(typeof(mergedConfig.selected_event_name) !== 'undefined' && mergedConfig.selected_event_name) {
            settings.setEventName(mergedConfig.selected_event_name);
        }
        if(typeof(mergedConfig.selected_event_date) !== 'undefined' && mergedConfig.selected_event_date) {
            settings.setEventDate(mergedConfig.selected_event_date);
        }
        if(typeof(mergedConfig.audible_warnings) !== 'undefined') {
            settings.setAudible(mergedConfig.audible_warnings);
        }
        if(typeof(mergedConfig.cache_event_entry_data) !== 'undefined') {
            settings.setCachingEventEntryData(mergedConfig.cache_event_entry_data);
        }
        // console.log('setConfigFromObject this.config after: '+JSON.stringify(settings.config, null, 4));
        return true;
    },
    makeProxyUrl: (remoteUrl) => {
        let url = '';
        if(remoteUrl.includes('dev')) {
            url = settings.getDevProxyUrl();
        } else {
            url = settings.getProdProxyUrl();
        }
        return url + '?q='+remoteUrl;
    },
    getRemoteConfiguration: async (remoteUrl) => {
        console.log('SettingStore::getRemoteConfiguration remote url: '+remoteUrl);

        const url = settings.makeProxyUrl(remoteUrl);
        console.log('SettingStore::getRemoteConfiguration url to fetch: '+url);

        return await fetch(url, {
            method:'GET'
        })
            .then(response => {
                if (!response.ok) {
                    const message = `An error has occurred: ${response.status}`;
                    throw new Error(message);
                }
                return response;
            })
            .then(response => response.json())
            .then(json => settings.loadExternalConfig(json)
            )
            .catch(function(error) {
                console.error("SettingStore::getRemoteConfiguration err: "+error);
                return false;
            })
    },
    loadExternalConfig: (externalConfig) => {
        return new Promise( (resolve, reject) => {
            console.log('loadExternalConfig type:'+typeof(externalConfig)+' json: '+externalConfig);
            let config = {};
            // convert to object
            if(typeof(externalConfig) === 'string') {
                config = JSON.parse(externalConfig);
            } else {
                config = externalConfig;
            }
            const mappedConfig = settings.mapRemoteConfigToLocal(config);
            settings.setConfigFromObject(mappedConfig);
            settings.setEventId('');
            settings.setEventDate('');
            settings.setEventName('');
            settings.saveConfig();
            resolve(true);
        });
    },
    reset: () => {
        settings.setConfigFromObject(settings.config_defaults);
        settings.saveConfig();
    },
    hasEntityConfig: () => {
        return settings.getEntityId() && settings.getUsername() && settings.getApiUri();
    },
    hasEventConfig: () => {
        return settings.getEventId() && settings.getEventDate();
    },
    canImportConfig: () => {
        return settings.getProdProxyUrl() && settings.getDevProxyUrl();
    }

}

export default settings;
